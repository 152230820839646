import gold_diamond_holographic from './gold_diamond_holographic.png';
import gold_random from './gold_random.png';
import gold_random_holographic from './gold_random_holographic.png';
import rich_gold from './rich_gold.png';
import silver_diamond_holographic from './silver_diamond_holographic.png';
import silver_random from './silver_random.png';
import silver_random_holographic from './silver_random_holographic.png';
import silver_star from './silver_star.png';

export const glitter_style1 = [
  {
    source: gold_diamond_holographic,
    name: 'Gold Diamond Holographic',
  },
  {
    source: gold_random,
    name: 'Gold Random',
  },
  {
    source: gold_random_holographic,
    name: 'Gold Random Holographic',
  },
  {
    source: rich_gold,
    name: 'Rich Gold',
  },
  {
    source: silver_diamond_holographic,
    name: 'Silver Diamond Holographic',
  },
  {
    source: silver_random,
    name: 'Silver Random',
  },
  {
    source: silver_random_holographic,
    name: 'Silver Random Holographic',
  },
  {
    source: silver_star,
    name: 'Silver Star',
  },
];
