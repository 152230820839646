import bluegum from './bluegum.webp';
import silver_sands from './silver_sands.webp';
import slate_grey from './slate_grey.webp';
import french_grey from './french_grey.webp';
import granite from './granite.webp';
import bluestone from './bluestone.webp';
import gunmetal from './gunmetal.webp';
import charcoal from './charcoal.webp';
import jet_black from './jet_black.webp';
import chocolate from './chocolate.webp';
import brick_red from './brick_red.webp';
import dark_terracotta from './dark_terracotta.webp';

const colours1 = [
  {
    source: bluegum,
    name: 'Bluegum',
  },
  {
    source: silver_sands,
    name: 'Silver Sands',
  },
  {
    source: slate_grey,
    name: 'Slate Grey',
  },
  {
    source: french_grey,
    name: 'French Grey',
  },
  {
    source: granite,
    name: 'Granite',
  },
  {
    source: bluestone,
    name: 'Bluestone',
  },
  {
    source: gunmetal,
    name: 'Gunmetal',
  },
  {
    source: charcoal,
    name: 'Charcoal',
  },
  {
    source: jet_black,
    name: 'Jet Black',
  },
  {
    source: chocolate,
    name: 'Chocolate',
  },
  {
    source: brick_red,
    name: 'Brick Red',
  },
  {
    source: dark_terracotta,
    name: 'Dark Terracotta',
  },
];

export default colours1;
