import ash_neutral from './ash-neutral.jpg';
import ash_pipeline from './ash-pipeline.jpg';
import biscuit_merino from './biscuit-merino.jpg';
import bluestorm_neutral from './bluestorm-neutral.jpg';
import bluestorm_pipeline from './bluestorm-pipeline.jpg';
import bullseye_white from './bullseye-white.jpg';

export const ultra_flake_styles1 = [
  {
    source: ash_neutral,
    name: 'Ash Neutral',
  },
  {
    source: ash_pipeline,
    name: 'Ash Pipeline',
  },
  {
    source: biscuit_merino,
    name: 'Biscuit Merino',
  },
  {
    source: bluestorm_neutral,
    name: 'Bluestorm Neutral',
  },
  {
    source: bluestorm_pipeline,
    name: 'Bluestorm Pipeline',
  },
  {
    source: bullseye_white,
    name: 'Bullseye White',
  },
];
