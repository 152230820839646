const homepageFAQs = [
  {
    question:
      'What is spray-crete floor, and how does it differ from traditional concrete replacement?',
    answer:
      'Spray-crete floor is thin concrete coating for resurfacing existing floors, quicker install, decorative options. Traditional replacement: removes old concrete, builds entirely new base for structural repairs',
  },
  {
    question:
      'How long does the spray-crete floor process take, and what is the downtime for my space?',
    answer:
      'The duration of the concrete resurfacing process varies depending on the size and complexity of the project. In general, our efficient installation process minimizes downtime, and we aim to complete most projects within a few days. We understand the importance of minimizing disruptions to your daily activities',
  },
  {
    question:
      'Can spray-crete floor be done on both indoor and outdoor surfaces?',
    answer:
      'Yes, spray-crete floors work for both indoor and outdoor concrete surfaces  due to their versatility and weather resistance',
  },
  {
    question:
      'How durable is the resurfaced concrete, and what kind of maintenance is required?',
    answer:
      'Spray-crete boasts high durability thanks to its strength and often added protective sealers.  Maintenance is minimal, typically involving regular cleaning and occasional resealing for long-lasting performance',
  },
  {
    question:
      'Can I choose a custom design or color for my resurfaced concrete surfaces?',
    answer:
      "Absolutely! Spray-crete's a great choice for customization. At RTQCR we offer a variety of colors and even design techniques for a unique concrete surface",
  },
];

export default homepageFAQs;
