import almond_and_grain_merino_base from './almond_and_grain_merino_base.jpeg';
import ash_and_grain_pipeline_grey from './ash_and_grain_pipeline_grey.jpeg';
import ash_and_iron_pipeline_grey from './ash_and_iron_pipeline_grey.jpeg';
import ash_and_silver_pipeline_grey from './ash_and_silver_pipeline_grey.jpeg';
import basalt_and_iron_dark_grey from './basalt_and_iron_dark_grey.jpeg';
import basalt_and_silver_dark_grey from './basalt_and_silver_dark_grey.jpeg';

export const grip_finish_style1 = [
  {
    source: almond_and_grain_merino_base,
    name: 'Almond And Grain Merino Base',
  },
  {
    source: ash_and_grain_pipeline_grey,
    name: 'Ash And Grain Pipeline Grey',
  },
  {
    source: ash_and_iron_pipeline_grey,
    name: 'Ash And Iron Pipeline Grey',
  },
  {
    source: ash_and_silver_pipeline_grey,
    name: 'Ash And Silver Pipeline Grey',
  },
  {
    source: basalt_and_iron_dark_grey,
    name: 'Basalt And Iron Dark Grey',
  },
  {
    source: basalt_and_silver_dark_grey,
    name: 'Basalt And Silver Dark Grey',
  },
];
