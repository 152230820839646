import basalt_dark from './basalt_dark_grey.jpg';
import basalt_pipeline_grey from './basalt_pipeline_grey.jpg';
import coral_pipeline_grey from './coral_pipeline_grey.jpg';
import quarry_pipeline_grey from './quarry_pipeline_grey.png';
import river_stone_neutral_grey from './river_stone_neutral_grey.jpg';
import river_stone_pipeline_grey from './river_stone_pipeline_grey.jpg';
import sandstone_merino from './sandstone_merino.jpg';
import sandstone_neutral_grey from './sandstone_neutral_grey.jpg';
import silver_stone_neutral_grey from './silver_stone_neutral_grey.png';

export const hyper_flake_styles1 = [
  {
    source: basalt_dark,
    name: 'Basalt Dark',
  },
  {
    source: basalt_pipeline_grey,
    name: 'Basalt Pipeline Grey',
  },
  {
    source: coral_pipeline_grey,
    name: 'Coral Pipeline Grey',
  },
  {
    source: quarry_pipeline_grey,
    name: 'Quarry Pipeline Grey',
  },
  {
    source: river_stone_neutral_grey,
    name: 'River Stone Neutral Grey',
  },
  {
    source: river_stone_pipeline_grey,
    name: 'River Stone Pipeline Grey',
  },
  {
    source: sandstone_merino,
    name: 'Sandstone Merino',
  },
  {
    source: sandstone_neutral_grey,
    name: 'Sandstone Neutral Grey',
  },
  {
    source: silver_stone_neutral_grey,
    name: 'Silver Stone Neutral Grey',
  },
];

export const hyper_flake_styles2 = [
  {
    source: basalt_dark,
    name: 'Basalt Dark',
  },
  {
    source: basalt_pipeline_grey,
    name: 'Basalt Pipeline Grey',
  },
  {
    source: coral_pipeline_grey,
    name: 'Coral Pipeline Grey',
  },
  {
    source: quarry_pipeline_grey,
    name: 'Quarry Pipeline Grey',
  },
  {
    source: river_stone_neutral_grey,
    name: 'River Stone Neutral Grey',
  },
  {
    source: river_stone_pipeline_grey,
    name: 'River Stone Pipeline Grey',
  },
  {
    source: sandstone_merino,
    name: 'Sandstone Merino',
  },
  {
    source: sandstone_neutral_grey,
    name: 'Sandstone Neutral Grey',
  },
  {
    source: silver_stone_neutral_grey,
    name: 'Silver Stone Neutral Grey',
  },
];
