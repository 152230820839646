const polishingFAQs = [
  {
    question: 'What is the longevity of polished concrete?',
    answer:
      "Polished concrete is extremely durable and can last for many years with proper maintenance. It's a great choice for both residential and commercial spaces due to its resilience.",
  },
  {
    question: 'Is polished concrete suitable for both homes and businesses?',
    answer:
      'Yes, polished concrete is a versatile solution that fits both residential and commercial settings.',
  },
  {
    question: 'How much time is needed to polish concrete?',
    answer:
      'The time required can vary based on the size and complexity of the project, but typically it ranges from 2 to 4 days.',
  },
  {
    question: 'What care does polished concrete require?',
    answer:
      "Polished concrete is low-maintenance. Regular sweeping and occasional damp mopping is usually all that's needed to keep it looking its best.",
  },
  {
    question: 'Can I choose a specific look for my polished concrete?',
    answer:
      'Absolutely, at RTQ Concrete Resurfacing, we offer a variety of styles and finishes to match your aesthetic preferences.',
  },
];

export default polishingFAQs;
