const sealingFAQs = [
  {
    question: 'How durable is concrete sealing?',
    answer:
      "Absolutely! Concrete sealing is robust and suitable for various environments. Whether it's a residential driveway, commercial parking lot, or industrial warehouse, our concrete sealing solutions are adaptable to meet different needs.",
  },
  {
    question:
      'Can concrete sealing be applied in residential as well as commercial spaces?',
    answer: 'Yes',
  },
  {
    question: 'How long does the sealing process take?',
    answer:
      'Depending on the scale of the project, the process can take anywhere between 1 to 3 days',
  },
  {
    question: 'What is the maintenance routine for sealed concrete?',
    answer:
      "Doesn't require special maintenance, but regular cleaning is recommended",
  },
  {
    question:
      'Can the appearance of sealed concrete be customized to match my style and preferences?',
    answer:
      'Yes, RTQ Concrete Resurfacing offers a wide range of designs and colours to match your preference',
  },
];

export default sealingFAQs;
