import block from './block.webp';
import rustic_brick from './rustic_brick.webp';
import mediterranean from './mediterranean.webp';
import convict_brick from './convict_brick.webp';
import brick from './brick.webp';
import bushrock from './bushrock.webp';
import classic_herringbone from './classic_herringbone.webp';
import diamond_tile from './diamond_tile.webp';
import european_fan from './european_fan.webp';
import basket_weave from './basket_weave.webp';
import quarry_tile from './quarry_tile.webp';
import ashlar_slate from './ashlar_slate.webp';

const stencils1 = [
  {
    source: block,
    name: 'Block',
  },
  {
    source: rustic_brick,
    name: 'Rustic Brick',
  },
  {
    source: mediterranean,
    name: 'Mediterranean',
  },
  {
    source: convict_brick,
    name: 'Convict Brick',
  },
  {
    source: brick,
    name: 'Brick',
  },
  {
    source: bushrock,
    name: 'Bushrock',
  },
  {
    source: classic_herringbone,
    name: 'Classic Herringbone',
  },
  {
    source: diamond_tile,
    name: 'Diamond Tile',
  },
  {
    source: european_fan,
    name: 'European Fan',
  },
  {
    source: basket_weave,
    name: 'Basket Weave',
  },
  {
    source: quarry_tile,
    name: 'Quarry Tile',
  },
  {
    source: ashlar_slate,
    name: 'Ashlar Slate',
  },
];

export default stencils1;
