import sandstone from './sandstone.webp';
import chestnut from './chestnut.webp';
import lite_mocha from './lite_mocha.webp';
import merino from './merino.webp';
import sandy_beige from './sandy_beige.webp';
import cream from './cream.webp';
import white from './white.webp';
import light_terracotta from './light_terracotta.webp';

const colours2 = [
  {
    source: light_terracotta,
    name: 'Light Terracotta',
  },
  {
    source: sandstone,
    name: 'Sandstone',
  },
  {
    source: chestnut,
    name: 'Chestnut',
  },
  {
    source: lite_mocha,
    name: 'Lite Mocha',
  },
  {
    source: merino,
    name: 'Merino',
  },
  {
    source: sandy_beige,
    name: 'Sandy Beige',
  },
  {
    source: cream,
    name: 'Cream',
  },
  {
    source: white,
    name: 'White',
  },
];

export default colours2;
