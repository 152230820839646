const colours2 = [
  {
    colour: '#aeaca4',
    name: 'Bluegum',
  },
  {
    colour: '#999594',
    name: 'french grey',
  },
  {
    colour: '#a79785',
    name: 'silver sands',
  },
  {
    colour: '#736a69',
    name: 'slate grey',
  },
];

export default colours2;
