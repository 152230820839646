import flagstone from './flagstone.webp';
import english_cobble from './english_cobble.webp';
import regal_tile from './regal_tile.webp';
import keystone from './keystone.webp';
import random_bluestone from './random_bluestone.webp';
import cobblestone from './cobblestone.webp';
import antique_tile from './antique_tile.webp';
import large_tile from './large_tile.webp';
import keystone_header from './keystone_header.webp';
import brick_header from './brick_header.webp';
import cobble_header from './cobble_header.webp';
import large_tile_header from './large_tile_header.webp';
import edge_brick_header from './edge_brick_header.webp';
import star_compass from './star_compass.webp';
import rosette from './rosette.webp';

const stencils2 = [
  {
    source: flagstone,
    name: 'Flagstone',
  },
  {
    source: edge_brick_header,
    name: 'Edge Brick Header',
  },
  {
    source: english_cobble,
    name: 'English Cobble',
  },
  {
    source: regal_tile,
    name: 'Regal Tile',
  },
  {
    source: keystone,
    name: 'Keystone',
  },
  {
    source: random_bluestone,
    name: 'Random Bluestone',
  },
  {
    source: cobblestone,
    name: 'Cobblestone',
  },
  {
    source: antique_tile,
    name: 'Antique Tile',
  },
  {
    source: large_tile,
    name: 'Large Tile',
  },
  {
    source: keystone_header,
    name: 'Keystone Header',
  },
  {
    source: brick_header,
    name: 'Brick Header',
  },
  {
    source: cobble_header,
    name: 'Cobble Header',
  },
  {
    source: large_tile_header,
    name: 'Large Tile Header',
  },
  {
    source: star_compass,
    name: 'Star Compass',
  },
  {
    source: rosette,
    name: 'Rosette',
  },
];

export default stencils2;
