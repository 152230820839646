import img10 from './spray_10.webp';
import img11 from './spray_11.webp';
import img12 from './spray_12.webp';
import img13 from './spray_13.webp';
import img14 from './spray_14.webp';
import img15 from './spray_15.webp';
import img16 from './spray_16.webp';
import img17 from './spray_17.webp';
import img18 from './spray_18.webp';

const gallery2 = [
  { id: 10, source: img10 },
  { id: 11, source: img11 },
  { id: 12, source: img12 },
  { id: 13, source: img13 },
  { id: 14, source: img14 },
  { id: 15, source: img15 },
  { id: 16, source: img16 },
  { id: 17, source: img17 },
  { id: 18, source: img18 },
];

export default gallery2;
