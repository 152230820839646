const googleReviews = [
  {
    profileImg:
      'https://lh3.googleusercontent.com/a/ACg8ocJbHVmhzZhRaUHoZn_ZmHJLAMBdi8JgCocR5NKf8HsiFO2wbQ=w60-h60-p-rp-mo-br100',
    name: 'Mauricio Iturrieta',
    date: '2 Weeks Ago',
    rating: 5,
    review:
      'RTQ just did the epoxy in my workshop and I’m beyond happy with their service and workmanship!  His pricing was very reasonable versus some of the other companies out there. He showed me a few options of base colours and flakes. He did exactly what I wanted and the results were amazing. I needed this job done on a short notice and RTQ fit me in and got me out of trouble! I couldn’t recommend him high enough. Thanks again',
    link: 'https://g.co/kgs/Q47b3DM',
  },
  {
    profileImg:
      'https://lh3.googleusercontent.com/a/ACg8ocLVVFa5WzlyHToCBXuwn2I3rClmPqTAC8Ks8SHybX9e-5gXFg=s36-c-rp-mo-br100',
    name: 'Kylie Crawley',
    date: '1 month Ago',
    rating: 5,
    review:
      'I highly recommend Ray from RTQ Concrete resurfacing. We had some concrete resurfaced approx 18 months ago in our backyard. It still looks as good as when it was first done. The concrete looks so good we got Ray back this week to redo our driveway. Wow is all I can say - the work is top quality. Thanks once again we will be back soon for our garage to be done too!',
    link: 'https://g.co/kgs/W2dZXxU',
  },
  {
    profileImg:
      'https://lh3.googleusercontent.com/a-/ALV-UjXjASgCxykWEkImMguCmxBy_EHMgma7nNKVFbNdV3BqoT8KP8X1=s36-c-rp-mo-ba3-br100',
    name: 'Sunil Parajuli',
    date: '1 month Weeks Ago',
    rating: 5,
    review:
      'Highly recommend Ray for resurfacing.. Very honest n high dedication on his work..',
    link: 'https://g.co/kgs/9WTPbS5',
  },
  {
    profileImg:
      'https://lh3.googleusercontent.com/a/ACg8ocKLwiH1_UJKpteVuS3MIfT4j9t4V4a1_uLKs3C1HvWX50tOvg=s36-c-rp-mo-br100',
    name: 'Ali Al',
    date: '2 months Ago',
    rating: 5,
    review: 'Very good work! Highly recommend',
    link: 'https://g.co/kgs/xi8BTX1',
  },
];

export default googleReviews;
