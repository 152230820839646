const homepageFAQs = [
  {
    question: 'What services does RTQ Concrete Resurfacing offer?',
    answer:
      'We offer 4 services Epoxy Flooring, Spray-Crete Floor, Concrete Sealing and Polished Concrete',
  },
  {
    question:
      "How do I know if my project is suitable for RTQ Concrete Resurfacing's services?",
    answer:
      'Contact our team and we will find out if our service is suitable for you',
  },
  {
    question: 'What areas does RTQ Concrete Resurfacing serve?',
    answer: 'We serve all Greater Sydney area',
  },
  {
    question: 'How long does a typical concrete project take?',
    answer:
      'Depending on the scale of the project, the project can take anywhere between 1 to 5 days',
  },
  {
    question:
      'Does RTQ Concrete Resurfacing provide consultations or estimates?',
    answer:
      'Yes, RTQ Concrete Resurfacing offers consultations and estimates for your projects. Get in touch with us, and our experienced team will assess your needs, provide recommendations, and offer a transparent estimate for the scope of work required.',
  },
];

export default homepageFAQs;
