const why = [
  {
    id: 1,
    title: 'Improved air quality',
    body: 'Improve indoor air quality by minimizing dust and allergens, making polished concrete ideal for allergy sufferers and those with respiratory issues.',
  },

  {
    id: 2,
    title: 'Enhanced Durability',
    body: 'Strengthen concrete surfaces, reducing the risk of cracks, chips, and wear-and-tear over time',
  },

  {
    id: 3,
    title: 'Appearance',
    body: 'Achieve a sleek, high-gloss finish that reflects natural light and brightens any space, from homes to commercial buildings',
  },

  {
    id: 4,
    title: 'Low Maintenance',
    body: 'Eliminate the need for coatings or waxing, saving time and money on maintenance',
  },
];

export default why;
