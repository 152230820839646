const why = [
  {
    id: 1,
    title: 'Renewed Aesthetics',
    body: 'Give your concrete surfaces a fresh, modern look. Our resurfacing options come in various finishes and textures, allowing you to achieve the aesthetic you desire',
  },

  {
    id: 2,
    title: 'Enhanced Durability',
    body: 'Strengthen and protect your concrete surfaces against the elements. Our resurfacing materials provide an extra layer of durability, ensuring longevity and resistance to wear and tear',
  },

  {
    id: 3,
    title: 'Cost-Effective Solution',
    body: 'Concrete resurfacing is a cost-effective alternative to full replacement. Save on the expense of removing and pouring new concrete while achieving a revitalised appearance',
  },

  {
    id: 4,
    title: 'Customization Options',
    body: 'Express your style with a range of customization options. Choose from different colours, patterns, and textures to tailor the resurfacing to your unique preferences',
  },

  {
    id: 5,
    title: 'Quick Installation',
    body: 'Experience minimal disruption to your daily activities. Our efficient installation process ensures a swift transformation of your space without lengthy downtimes',
  },
];

export default why;
