import brass from './brass.png';
import bronze from './bronze.png';
import copper from './copper.png';
import crimson_red from './crimson_red.png';
import silver from './silver.png';
import pearl from './pearl.png';
import sea_breeze from './sea_breeze.png';
import violet from './violet.png';
import emerald from './emerald.png';
import teal from './teal.png';
import deep_ocean from './deep_ocean.png';

export const metallic_style1 = [
  {
    source: brass,
    name: 'Brass',
  },
  {
    source: bronze,
    name: 'Bronze',
  },
  {
    source: copper,
    name: 'Copper',
  },
  {
    source: crimson_red,
    name: 'Crimson Red',
  },
  {
    source: silver,
    name: 'Silver',
  },
  {
    source: pearl,
    name: 'Pearl',
  },
  {
    source: sea_breeze,
    name: 'Sea Breeze',
  },
  {
    source: violet,
    name: 'Violet',
  },
  {
    source: emerald,
    name: 'Emerald',
  },
  {
    source: teal,
    name: 'Teal',
  },
  {
    source: deep_ocean,
    name: 'Deep Ocean',
  },
];
