const why = [
  {
    id: 1,
    title: 'Unparalleled Durability',
    body: 'Our epoxy flooring is engineered to withstand heavy foot traffic, spills, and daily wear and tear. Say goodbye to worries about scratches, stains, or unsightly damage – your floors will maintain their flawless finish for years to come',
  },

  {
    id: 2,
    title: 'Seamless Style',
    body: "Create a sleek, polished look that effortlessly complements any design aesthetic. Our epoxy flooring comes in a variety of colours and finishes, allowing you to customise your space with ease. Whether you prefer a contemporary sheen or a more traditional matte finish, we've got you covered",
  },

  {
    id: 3,
    title: 'Low Maintenance, High Impact',
    body: "Tired of spending hours on floor upkeep? Epoxy flooring is not only resistant to dirt and grime but also incredibly easy to clean. Enjoy a low-maintenance solution that doesn't compromise on aesthetics",
  },

  {
    id: 4,
    title: 'Versatility at its Best',
    body: 'Perfect for residential, commercial, or industrial spaces, epoxy flooring adapts to your needs. From garages to living rooms, kitchens to warehouses, experience the versatility that sets our flooring apart',
  },

  {
    id: 5,
    title: 'Eco-friendly Choices',
    body: 'We understand the importance of sustainability. Our epoxy flooring options are crafted with environmentally friendly materials, ensuring you make a responsible choice without compromising on quality',
  },
];

export default why;
