const why = [
  {
    id: 1,
    title: 'Protection',
    body: 'Protects concrete from stains, oil spills, and water damage, preserving its appearance and structural integrity',
  },

  {
    id: 2,
    title: 'Enhanced Durability',
    body: 'Extends the lifespan of concrete by preventing cracks and erosion caused by environmental factors',
  },

  {
    id: 3,
    title: 'Appearance',
    body: 'Enhances the appearance of concrete surfaces with a glossy finish that adds aesthetic appeal to residential and commercial spaces.',
  },

  {
    id: 4,
    title: 'Low Maintenance',
    body: 'Simplifies cleaning and maintenance by creating a smooth, non-porous surface that resists dirt and grime buildup',
  },
];

export default why;
