const epoxyFAQs = [
  {
    question: 'How durable is epoxy flooring?',
    answer:
      "Absolutely! Epoxy flooring is versatile and suitable for various settings. Whether it's a residential garage, commercial showroom, or industrial facility, our epoxy flooring solutions are adaptable to meet different needs.",
  },
  {
    question:
      'Can epoxy flooring be installed in residential as well as commercial spaces?',
    answer: 'Yes',
  },
  {
    question: 'How long does the installation process take?',
    answer:
      'Depending on the scale of the project, the project can take anywhere between 1 to 5 days',
  },
  {
    question: 'What is the maintenance routine for epoxy flooring?',
    answer: "Doesn't require special maintenace",
  },
  {
    question:
      'Can epoxy flooring be customized to match my style and preferences?',
    answer:
      'Yes, RTQ Concrete Resurfacing offers a wide range of designs and colour to match your preference',
  },
];

export default epoxyFAQs;
