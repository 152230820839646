import img1 from './spray_1.webp';
import img2 from './spray_2.webp';
import img3 from './spray_3.webp';
import img4 from './spray_4.webp';
import img5 from './spray_5.webp';
import img6 from './spray_6.webp';
import img7 from './spray_7.webp';
import img8 from './spray_8.webp';
import img9 from './spray_9.webp';

const gallery1 = [
  { id: 1, source: img1 },
  { id: 2, source: img2 },
  { id: 3, source: img3 },
  { id: 4, source: img4 },
  { id: 5, source: img5 },
  { id: 6, source: img6 },
  { id: 7, source: img7 },
  { id: 8, source: img8 },
  { id: 9, source: img9 },
];

export default gallery1;
