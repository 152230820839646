import img10 from './epoxy_10.webp';
import img11 from './epoxy_11.webp';
import img12 from './epoxy_12.webp';
import img13 from './epoxy_13.webp';
import img14 from './epoxy_14.webp';
import img15 from './epoxy_15.webp';
import img16 from './epoxy_16.webp';
import img17 from './epoxy_17.webp';
import img18 from './epoxy_18.webp';

const gallery2 = [
  { id: 1, source: img10 },
  { id: 2, source: img11 },
  { id: 3, source: img12 },
  { id: 4, source: img13 },
  { id: 5, source: img14 },
  { id: 6, source: img15 },
  { id: 7, source: img16 },
  { id: 8, source: img17 },
  { id: 9, source: img18 },
];

export default gallery2;
