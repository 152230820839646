export const plain_epoxy_colour1 = [
  {
    colour: '#cccccc',
    name: 'Neutral Grey',
  },
  {
    colour: '#999999',
    name: 'Pipeline Grey',
  },
  {
    colour: '#4b5259',
    name: 'Dark Grey',
  },
  {
    colour: '#918f88',
    name: 'Koala Grey',
  },
  {
    colour: '#767779',
    name: 'Bridge Grey',
  },
  {
    colour: '#c9b79e',
    name: 'Merino',
  },
  {
    colour: '#2a2a2c',
    name: 'Black',
  },
  {
    colour: '#ffffff',
    name: 'White',
  },
  {
    colour: '#a6a7a1',
    name: 'Light Grey',
  },
  {
    colour: '#5b94d1',
    name: 'Blueball',
  },
];
