const colours1 = [
  {
    colour: '#ffffff',
    name: 'White',
  },
  {
    colour: '#d3ba9b',
    name: 'Cream',
  },
  {
    colour: '#d6b182',
    name: 'Sandy Beige',
  },
  {
    colour: '#b29477',
    name: 'Merino',
  },
  {
    colour: '#866c5a',
    name: 'Lite Mocha',
  },
  {
    colour: '#be6d40',
    name: 'Light Terracotta',
  },
  {
    colour: '#9b5637',
    name: 'Dark Terracotta',
  },
  {
    colour: '#933b2c',
    name: 'Brick Red',
  },
];

export default colours1;
