import img1 from './epoxy_1.webp';
import img2 from './epoxy_2.webp';
import img3 from './epoxy_3.webp';
import img4 from './epoxy_4.webp';
import img5 from './epoxy_5.webp';
import img6 from './epoxy_6.webp';
import img7 from './epoxy_7.webp';
import img8 from './epoxy_8.webp';
import img9 from './epoxy_9.webp';

const gallery1 = [
  { id: 1, source: img1 },
  { id: 2, source: img2 },
  { id: 3, source: img3 },
  { id: 4, source: img4 },
  { id: 5, source: img5 },
  { id: 6, source: img6 },
  { id: 7, source: img7 },
  { id: 8, source: img8 },
  { id: 9, source: img9 },
];

export default gallery1;
