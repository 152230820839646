import artic_perwinkle from './arctic_perwinkle.png';
import grain_merino from './grain_merino.png';
import iron_dark_grey from './iron_dark_grey.png';
import iron_pipeline_grey from './iron_pipeline_grey.png';
import silver_pipeline_grey from './silver_pipeline_grey.png';
import wine_red_oxide from './wine_red_oxide.png';

export const quartz_shield_style1 = [
  {
    source: artic_perwinkle,
    name: 'Artic Perwinkle',
  },
  {
    source: grain_merino,
    name: 'Grain Merino',
  },
  {
    source: iron_dark_grey,
    name: 'Iron Dark Grey',
  },
  {
    source: iron_pipeline_grey,
    name: 'Iron Pipeline Grey',
  },
  {
    source: silver_pipeline_grey,
    name: 'Silver Pipeline Grey',
  },
  {
    source: wine_red_oxide,
    name: 'Wine Red Oxide',
  },
];
